import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierPasha = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierPasha1 {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { regex: "/Pasha/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Pasha Watches for Sale'}
      canonical={'/fine-watches/cartier/cartier-pasha/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Pasha Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/model/miss-pasha,pasha,pasha-35,pasha-38mm,pasha-42mm,pasha-chronograph/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-pasha-header.png"
                alt="Pre-Owned Certified Used Cartier Pasha Header"
                aria-label="Used Cartier Pasha Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTER PASHA</h1>
            <h2>
              {' '}
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AND MODERN CARTIER PASHA
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER PASHA WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              We are a real watch and jewelry store located in Miami. We are across from Bal Harbour
              Shops, with real watch and jewelry experts on-site. We're open six days a week and not
              a virtual store, however we also have a website. Our customers say we are the #1 place
              in Miami to buy used watches. We're proudly assisting customers for the past 42 years.
              We're sellers and buyers of pre-owned Cartier Pasha watches in Miami, Miami Beach,
              Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles ,
              Aventura, Fort Lauderdale and more areas.
              <br />
              <br />
              These days, many people are saying," I want to sell my luxury watch for cash." If you
              are thinking of selling your watch or other jewelry for cash, contact Gray and Sons.
              Gray and Sons pays the highest for used watches and jewelry. We are a proudly
              independent Cartier watch service center. We will replace your Cartier Pasha watch
              battery or find you a new strap.
              <br />
              <br />
              According to the story, the first waterproof Cartier Pasha watch was created for the
              Pasha of Marrakech in 1933. Today, the Pasha de Cartier watch collection includes
              diamond-studded Miss Pasha, Pasha C, and chronograph models such as the Pasha Seatimer
              Chronograph with water resistance up to 100 meters. What are the current prices for
              pre-owned Cartier watches? Come find out at Gray and Sons Jewelers in Miami.
              <br />
              <br />
              Cartier Pasha, Cartier Ballon Bleu, Cartier Santos, and Cartier Tank are among the
              most desirable Cartier models. Owning a prestigious and incredibly valuable Cartier
              wristwatch is not only for pleasure but also for investment. If you’re thinking of
              buying a Cartier Pasha watch for yourself, make sure you know where to look for the
              best prices on the market. You can also avoid waiting lists, unavailable models, and
              extremely high prices. How? Gray &amp; Sons! Our customers call us the #1 seller of
              pre-owned Cartier watches in the U.S. We have been specializing in selling luxury
              jewelry and certified watches for over 40 years. The company is valued for its vast
              inventory of diamond Cartier watches and several Cartier models in yellow gold,
              stainless steel, and white gold. Every Cartier watch purchased at Gray &amp; Sons
              comes with 1-year Gray &amp; Sons Jewelers Warranty. The company is also known for our
              professional luxury watch repair for fine Swiss watches, including Cartier watches
              with diamond bezel, Rolex, Omega, Patek Philippe, and more. Gray and Sons offers
              certified pre-owned Ballon Bleu Cartier watches. Our watches have been
              recently serviced, timing adjusted, freshly lubricated and detailed and come with our
              exclusive in house one year warranty, and two years warranty for modern Rolex watches.
              <br />
              <br />
              If you’re looking for a certified pre-owned Cartier watch for the best price, you’re
              in the right place. Check the available models on our website &ensp;
              <a href="/fine-watches/cartier/cartier-pasha/">www.grayandsons.com</a>
              . Need more details? Come and visit Gray &amp; Sons’ Showroom in Surfside, Miami, and
              meet our highly qualified in-house watchmakers and jewelers who are happy to help
              every customer who comes to Gray &amp; Sons. Choose Gray &amp; Sons for the largest
              selection of used Cartier watches. We offer certified pre-owned
              watches.
              <br />
              <br />
              Popular Reference Numbers For Cartier’s Pasha watch are W31089M7, W301970M, W301980M,
              WJ130001,11986, w31030h3, WJ116156, 2861, W31080M7, W3108555, W31088U2, W3101755. Two
              great Cartier’s Pasha models in the Gray and Sons Inventory are R1986 in 18k Yellow
              Gold, 43mm Automatic watch. This watch features Cartier’s classic silver dial with
              Arabic numerals per quarter and a date aperture between 4 and 5 O’clock. Comes with
              box. A second great Cartier Pasha from the Gray and Sons inventory is the Cartier
              Pasha Chronograph w31030h3 Stainless Steel 38mm Automatic watch. This sport watch
              comes on a stainless Steel bracelet with a stainless steel deploy buckle. Gray and
              Sons Jewelry is the best place to buy a Cartier Pasha watch and the best place to
              repair and sell your Cartier Pasha watch. If you are one of the many people saying, “I
              want to sell my luxury watch for cash” then come to Gray and Sons or &ensp;
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a> and we will buy
              your used watch for cash.
              <br />
              <br />
              Next time you hear someone say, “I want to sell my Cartier Pasha wristwatch for cash”
              send them to Gray and Sons Jewelry and www.sellusyourjewelry.com will buy it for cash.
              Would you like to MAKE AN OFFER on watches and jewelry? Vika and Rich are live expert
              decision makers and ready to work with you now. If you have questions about our
              products or any of the luxury watches and jewelry we offer, please{' '}
              <a href="/chat-with-rich">REACH OUT</a> to a representative for further assistance.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'Cartier Pasha'}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierPasha
